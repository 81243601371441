@media (max-width: 768px) {
    .menu-bar.hide {
        display: none;
    }

    .sidebar.close .menu-bar {
        display: none;
    }

    .sidebar.close {
        width: 98px;
        height: 0;
        background-color: transparent;
    }

    .sidebar {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        padding: 0;
        background-color: var(--white-color);
        z-index: 100;
    }

    .sidebar_logo {
        height: 60px;
        align-items: center;
        display: flex;
        width: 50rem;
        background: var(--white-color);
    }

    .sidebar header .toggle {
        cursor: pointer;
        position: absolute;
        top: 50%;
        right: 25px;
        font-size: 22px;
        height: 20px;
        width: 20px;
        padding: 1px;
    }

    .sidebar.close header .toggle {
        display: block;
        position: absolute;
        top: 50%;
        right: -21px;
    }

    .sidebar.close header .img-text {
        padding: 0 2%;
    }

    .img-text .img-logo img {
        padding: 0 0 0 10px;
    }

    .bottom-content .nav-link {
        margin-bottom: 35px;
    }

    .sidebar.close header .img-text .img-logo img {
        width: 70px;
    }

    .sidebar .menu-bar {
        padding: 0 4%;
    }

    .home,
    .packages-hero,
    .subscriptions-hero,
    .notifications-hero,
    .class-hero,
    .note,
    .add_Subs,
    .change-password-hero,
    .section-hero {
        padding: 8% 3.5% 0;
        left: 0;
        width: calc(100% - 0);
    }

    .sidebar.close~.home,
    .sidebar.close~.note,
    .sidebar.close~.add_Subs,
    .sidebar.close~.successful-body,
    .sidebar.close~.error-body,
    .sidebar.close~.subscriptions-hero,
    .sidebar.close~.packages-hero,
    .sidebar.close~.class-hero,
    .sidebar.close~.classlist-hero,
    .sidebar.close~.notifications-hero,
    .sidebar.close~.change-password-hero,
    .sidebar.close~.marketing-hero,
    .sidebar.close~.section-hero
     {
        left: 0;
        width: calc(100% - 0px);
    }



    /* gym header */
    .home-header {
        padding: 8% 0 3%;
    }

    .gym-text>h3 {
        font-size: 20px;
    }

    .gym-text>p {
        font-size: 14px;
    }

    .operator-profil>.profil {
        width: 40px;
        height: 40px;
    }

    .operator-profil>.profil>img {
        height: 30px;
    }

    /* Login */

    .login_wrapper {
        display: flex;
        flex-direction: column;
    }

    .login__body {
        display: flex;
        flex-direction: column;
        gap: 50px;
        width: 75%;
        padding: 5% 4%;
    }

    .login__carousel {
        display: none;
    }


    /* home */

    .home-btn .ads-subs,
    .home-btn .attendance-number {
        width: 220px;
        height: 100px;
    }

    .home-btn .ads-subs>a {
        padding: 7% 8%;
    }

    .attendance-number .attendance-icon {
        width: 47px;
        height: 47px;
    }

    .attendance-count>p {
        font-size: 14px;
    }

    .attendance-count>span {
        font-size: 20px;
    }

    .home .home-btn {
        padding: 3% 0 6%;
    }

    /* Add subs & note */
    .note,
    .add_Subs {
        padding: 13% 2.5% 0;
    }

    .section_header>h3 {
        font-size: 20px;
    }

    /* packages */
    .packages-header,
    .subs-header {
        padding: 0 0 25px;
    }

    /* Subscriptions */
    .subs-button>a {
        font-size: 13px;
    }

    .button-container {
        gap: 0 !important;
    }

    /* classlist */
    .section_header>h3 {
        font-size: 20px;
    }

    .classlist-reserve__wrapper {
        padding: 2% 0 5%;
    }

    .classlist-header {
        padding: 5px 0 25px;
    }

    .classlist-reserve-bg {
        width: 70%;
    }

    .reserve-btn>button {
        font-size: 14px;
        letter-spacing: .5px;
        width: 134px;
    }

    .reserve-number_bg {
        width: 20%;
    }

    .reserve-number_body>p {
        font-size: 14px;
    }

    .classlist_name>h5 {
        font-size: 18px;
    }

    /* change password */
    /* .change-password-header {
        padding: 13% 2.5% 4%;
    } */
    .change-password-hero {
        padding: 13% 2.5% 0;
    }
}